<template>
  <div class="hk_page_routerview">
    <div class="hk_page_title">课程管理
      <div class="hk_page_title_right">
        <el-dropdown size="small" @command="handleCommand">
          <el-button type="primary" plain size="small">
            批量导入<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="course">导入课程</el-dropdown-item>
            <el-dropdown-item command="class">导入课节</el-dropdown-item>
            <el-dropdown-item command="fudao">导入辅导</el-dropdown-item>
            <el-dropdown-item command="attend">导入考勤表</el-dropdown-item>
            <el-dropdown-item command="homework">导入作业表</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="hk_page_wrap">
      <div class="hk-common-page">
        <div class="hk_search_box">
          <div class="hk_search_box_item">
            搜索：
            <el-input debounce @keyup.13.native="searchFun" size="small" clearable placeholder="课程ID" v-model="eeoCourseId">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="hk_search_box_item">
            <el-input debounce @keyup.enter.native="searchFun" size="small" clearable placeholder="课程名称" v-model="courseName">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="hk_search_box_item">
            <el-select size="small" v-model="teacherId" clearable filterable placeholder="授课老师">
              <el-option :label="item.staffName" :value="item.staffId" :key="item.id" v-for="item in teacherList">{{ item.staffName }}</el-option>
            </el-select>

          </div>
          <div class="hk_search_box_item">

            <el-select size="small" v-model="assistantId" clearable filterable placeholder="辅导老师">
              <el-option :label="item.staffName" :value="item.staffId" :key="item.id" v-for="item in assistantList">{{ item.staffName }}</el-option>
            </el-select>
            <!-- <el-input debounce size="small" clearable placeholder="辅导老师" v-model="assistantId">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input> -->
          </div>
          <div class="hk_search_box_item">
            大小班：
            <el-select v-model="classType" clearable size="small" placeholder="请选择">
              <el-option label="大班" value="BIG">大班</el-option>
              <el-option label="小班" value="SMALL">小班</el-option>
            </el-select>

          </div>
          <div class="hk_search_box_item">
            课程类型：
            <el-select v-model="courseType" clearable size="small" placeholder="课程类型">
              <el-option label="长线课" value="LONG_COURSE">长线课</el-option>
              <el-option label="活动课" value="ACTIVITY_COURSE">活动课</el-option>
            </el-select>

          </div>
          <div class="hk_search_box_item">
            学年：
            <el-select v-model="courseYear" clearable size="small" placeholder="请选择">
              <el-option :label="item" :value="item" v-for="(item,idx) in  courseYearList" :key="idx">{{item}}</el-option>
            </el-select>
          </div>
          <div class="hk_search_box_item">
            学期：
            <el-select v-model="term" clearable size="small" placeholder="请选择">
              <el-option :label="txt" :value="key" :key="key" v-for="(txt,key) in termList">{{ txt}}</el-option>
            </el-select>
          </div>
          <div class="hk_search_box_item">
            年级：
            <el-select v-model="gradeList" clearable multiple collapse-tags size="small" placeholder="请选择">
              <el-option :label="txt" :value="key" :key="key" v-for="(txt,key) in gradeListObj">{{ txt}}</el-option>
            </el-select>

          </div>
          <div class="hk_search_box_item">
            学科：
            <el-select v-model="subjectTypeList" clearable multiple collapse-tags size="small" placeholder="请选择">
              <el-option :label="txt" :value="key" :key="key" v-for="(txt,key) in subjectTypeObj">{{ txt}}</el-option>
            </el-select>

          </div>
          <div class="hk_search_box_item">
            <el-button type="primary" size="small" @click="searchFun" v-loading.fullscreen.lock="pageLoading">搜索</el-button>
            <el-button type="default" size="small" @click="resetSearchFun" v-loading.fullscreen.lock="pageLoading">重置</el-button>
          </div>
        </div>
        <div class="hk_result_total_box">
          共有 <span class="hk-font-highlight">{{totalNum}}</span> 个课程
        </div>
        <div class="hk_custom_table">
          <el-table ref="myTable" :data="tableData" style="width: 100%">
            <el-table-column label="课程ID" width="110px">
              <template slot-scope="scope">
                {{ scope.row.eeoCourseId }}
              </template>
            </el-table-column>
            <el-table-column label="课程名称" width="220px">
              <template slot-scope="scope">
                {{ scope.row.courseName }}
              </template>
            </el-table-column>
            <el-table-column label="学年" width="130px">
              <template slot-scope="scope">
                <span>{{scope.row.courseYear}}</span>
              </template>
            </el-table-column>

            <el-table-column label="学期" width="110px">
              <template slot-scope="scope">
                <span>{{scope.row.term}}</span>
              </template>
            </el-table-column>
            <el-table-column label="学科" width="110px">
              <template slot-scope="scope">
                <span>{{ scope.row.subjectType}}</span>
              </template>
            </el-table-column>

            <el-table-column label="品类" width="110px">
              <template slot-scope="scope">
                <span>{{scope.row.category}}</span>
              </template>
            </el-table-column>
            <el-table-column label="大小班" :min-width="110">
              <template slot-scope="scope">
                <span>{{scope.row.classType}}</span>
              </template>
            </el-table-column>
            <el-table-column label="排课方式" width="120px">
              <template slot-scope="scope">
                <span>{{scope.row.arrangeType}}</span>
              </template>
            </el-table-column>
            <el-table-column label="课程类型" width="120px">
              <template slot-scope="scope">
                <span>{{scope.row.courseType}}</span>
              </template>
            </el-table-column>
            <el-table-column label="授课老师" width="120px">
              <template slot-scope="scope">
                <span>{{scope.row.teacherName}}</span>
              </template>
            </el-table-column>
            <el-table-column label="辅导老师" width="120px">
              <template slot-scope="scope">
                <span>{{scope.row.assistantName}}</span>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="180px" fixed="right">
              <template slot-scope="scope">
                <span class="hk-table-option hk-table-option-first" @click="seeLessonFun(scope.row)">查看课节</span>
                <span class="hk-table-option" @click="seeMidTermFun(scope.row)">期中报告</span>

              </template>
            </el-table-column>

            <div slot="empty">
              <p>没有你要找的数据哦！</p>
            </div>
          </el-table>
          <div class="hk_pagination" v-if="totalNum>0">
            <el-pagination @current-change="handleCurPageChange" @size-change="handleSizeChange" :current-page.sync="curPage" :page-size="pageSize" :page-sizes="[10,20, 50, 100]" layout="total,sizes,prev, pager, next" :total="totalNum">
            </el-pagination>
            <!-- total,prev, pager, next -->
          </div>
        </div>
        <div class="hk_custom_scroll">
          <div ref="hkCustomScroll" class="hk-scroll" @scroll="handleScrollTop">
            <div class="hk-scroll-content" :style="'width:'+tableWidth+'px'"></div>
          </div>
        </div>

      </div>
    </div>

    <!-- 导入课程弹窗 ifCourseDialog,改为公用的导入弹窗 -->
    <el-dialog :visible.sync="ifCourseDialog" :modal-append-to-body="false" width="480px" @close="hideCourseDialog">
      <div slot="title">
        {{exportTitle}} <span class="hk-font-red hk-fontsize12">&nbsp;&nbsp;提示：仅支持CSV格式文件导入</span>
      </div>
      <div class="export-dialog">
        <div class="export-main">
          <div class="export-upload" v-if="!fileCourse">
            <div class="hk_upload_main">
              <!--暂不支持excel格式，等后续支持了再加上 ,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet -->
              <input class="hk_ipt_file" id="J_course_file" type="file" @change="changeUploadExcel('J_course_file','course')" accept=".csv">
              <div class="hk_upload_box">
                <div class="hk_upload-tag iconfont icon-jiahao"></div>
                <p class="hk_upload-txt">上传文件</p>
              </div>
            </div>
            &nbsp;&nbsp;
            <!-- 只有辅导才有模版 -->
            <a v-if="exportType=='fudao'" href="https://oss.ke.live/prod/module/fudao.csv" class="d-template" target="_blank">下载辅导模版</a>
          </div>
          <div class="export-remark" v-else>
            <el-form label-width="94px" size="small" :rules="dialogRules" ref="dialogForm" :model="dialogCourseForm">
              <el-form-item label="导入备注：" prop="remark">
                <div class="hk-flex">
                  <el-input v-model="dialogCourseForm.remark" placeholder="请输入"></el-input>
                  &nbsp;&nbsp;
                  <el-button type="primary" size="small" @click="exportFun('course','dialogForm')">导入</el-button>
                </div>

                <div class="hk-flex">

                  <div class="hk_upload_main">
                    <input class="hk_ipt_file" id="J_course_file_again" type="file" @change="changeUploadExcel('J_course_file_again','course','again')" accept=".csv">
                    <div class="hk_upload_box">
                      <p class="hk_upload-txt el-button el-button--primary el-button--small is-plain">
                        <span><i class="el-icon-upload el-icon--right"></i></span> 重新上传
                      </p>
                    </div>
                  </div>
                  <span style="color:rgb(69, 176, 88);font-size:26px;" class="iconfont icon-csv" v-if="ifAgain"> </span>

                </div>

              </el-form-item>

            </el-form>
          </div>
        </div>
        <div class="export-record">
          <h3 class="record-title">导入记录</h3>
          <div class="record-item-list">
            <div class="record-item" v-for="(item,idx) in recordList" :key="idx">{{item.staffName}} {{item.updateTime}}<span class="span">导入表格</span>{{item.remark}}</div>
          </div>
          <el-empty description="暂无导入记录" v-if="recordList.length<1"></el-empty>
        </div>

      </div>
    </el-dialog>

  </div>
</template>

<script>
import { GradeEnum, SubjectTypeEnum, TermEnum } from '../../utils/enums';
// import moment from 'moment';

export default {
  data() {
    return {
      tableWidth: 0,

      pageLoading: false, //页面loading
      eeoCourseId: "",
      courseName: "",
      teacherId: "",
      assistantId: "",
      classType: "",
      courseType: "",
      courseYear: "", //课程年份
      term: "",
      gradeList: [],
      subjectTypeList: [], //科目类型列表

      tableData: [], //列表中显示的数据(搜索后,只显示符合条件)
      pageSize: 50, //每页10条数据
      curPage: 1, //当前第几页
      totalNum: 0, //总共几条数据

      recordList: [], //上传记录

      gradeListObj: GradeEnum,
      subjectTypeObj: SubjectTypeEnum,
      termList: TermEnum,
      teacherList: [], //老师列表
      assistantList: [], //助教列表
      courseYearList: [], //学年列表

      exportTitle: '', //导入的标题
      exportType: '', //导入的类型
      ifCourseDialog: false, //导入课程的弹窗
      // ifClassDialog: false, //导入课节
      // ifFudaoDialog: false, //导入辅导的弹窗
      // ifAttendDialog: false, //导入考勤
      // ifHomeworkDialog: false, //导入作业

      ifAgain: false, //true-重新上传；false-第一次上传

      fileCourse: null,
      // fileClass: null,
      // fileFudao: null,
      // fileAttend: null,
      // fileHomework: null,

      dialogCourseForm: {
        remark: '',
      },
      // dialogClassForm: {
      //   remark: '',
      // },
      // dialogFudaoForm: {
      //   remark: '',
      // },
      // dialogAttendForm: {
      //   remark: "",
      // },
      // dialogHomeWorkForm: {
      //   remark: "",
      // },
      dialogRules: {
        remark: [
          { required: true, message: '请输入', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    let that = this;

    that.getTeacherList()

    that.getDataFun()
    that.getAssistantList();
    that.getCourseYearArrFun()
  },
  methods: {
    //顶部批量导入
    handleCommand(command) {
      let that = this;

      that.exportType = command
      that.ifCourseDialog = false


      if (command == 'course') { //导入课程

        that.exportTitle = '导入课程'
      } else if (command == 'class') { //导入课节
        // that.ifClassDialog = true
        that.exportTitle = '导入课节'
      } else if (command == 'fudao') { //导入辅导
        // that.ifFudaoDialog = true
        that.exportTitle = '导入辅导'
      } else if (command == "attend") {//导入考勤
        // that.ifAttendDialog = true;
        that.exportTitle = '导入考勤'
      } else {//导入作业
        // that.ifHomeworkDialog = true;
        that.exportTitle = '导入作业'
      }

      that.ifCourseDialog = true

      that.getRecordCourseUpload(command) //获取记录

    },

    //上传文件,若课程和辅导老师接口不一样，则需要分开写方法
    changeUploadExcel(eleId, type, again) {
      let that = this;
      if (again) {
        that.ifAgain = true //表示是重新上传后，需要显示文件图标
      }
      let file = document.getElementById(eleId).files[0];

      console.log(file, '-----eleId--', eleId)

      if (file) {
        that.fileCourse = file
      } else {
        that.$message.error('请先选择文件')
      }

    },

    //导入 先判断备注有没有填写
    exportFun(type, formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.pageLoading = true;
          let url = ''
          let file = null;
          let paramRemark = ''

          let etype = that.exportType;
          file = that.fileCourse
          paramRemark = that.dialogCourseForm.remark
          if (etype == 'course') { //导入课程
            url = '/manage/course/uploadCourseFile'
            // file = that.fileCourse
            // paramRemark = that.dialogCourseForm.remark

          } else if (etype == 'class') { //导入课节
            url = '/manage/course/uploadClassFile'
            // file = that.fileClass
            // paramRemark = that.dialogClassForm.remark
          } else if (etype == 'fudao') { //导入辅导
            url = '/manage/course/uploadAssistantDataFile'
            // file = that.fileFudao
            // paramRemark = that.dialogFudaoForm.remark
          } else if (etype == 'attend') { //导入考勤
            url = '/manage/course/uploadAttendFile'
            // file = that.fileAttend
            // paramRemark = that.dialogAttendForm.remark
          } else { //导入作业
            url = '/manage/course/uploadHomeworkFile'
            // file = that.fileHomework
            // paramRemark = that.dialogHomeWorkForm.remark
          }
          let fd = new FormData();
          fd.append("file", file);
          fd.append("remark", paramRemark)
          var xhr = new XMLHttpRequest();
          xhr.open("POST", that.interfacePre() + url);
          // xhr.setRequestHeader('Content-Type',''); //提示 501 Current request is not a multipart request
          // xhr.setRequestHeader('Content-Type','multipart/form-data; boundary=--------------------------479840371711055579746182')
          // form-data 会提示跨越
          xhr.setRequestHeader("Authorization", localStorage.getItem('hkmanage_session'));
          xhr.onload = function () {
            let backRes = JSON.parse(xhr.responseText);
            console.log(backRes, '---back xhr')
            if (backRes.code == 200) {
              that.$message({
                message: '导入成功',
                type: 'success'
              });
              //然后要再次获取记录
              that.getRecordCourseUpload(etype)
              that.getDataFun()
            } else {
              that.$message({
                message: '导入失败，' + backRes.message,
                type: 'error'
              });
              console.log('导入失败。')
            }
            setTimeout(() => {
              that.pageLoading = false;
            }, 500)
          };
          xhr.send(fd);

        } else {
          console.log('未输入')
        }
      });
    },

    //获取 课程文件的上传记录   "EEO_DATA_COURSE"--课程文件 EEO_DATA_CLASS--课节文件
    getRecordCourseUpload(type) {
      let that = this;
      let params = {
        pageNum: 1,
        pageSize: 500
      }
      let ossPath = ''
      if (type == 'course') { //课程文件
        ossPath = 'EEO_DATA_COURSE'
      } else if (type == 'class') { //课节文件
        ossPath = 'EEO_DATA_CLASS'
      } else if (type == 'fudao') {
        ossPath = 'EEO_DATA_ASSISTANT' //辅导
      } else if (type == 'attend') { //考勤文件
        ossPath = 'EEO_DATA_ATTEND'
      } else { //作业
        ossPath = 'EEO_DATA_HOMEWORK'
      }
      params.ossPath = ossPath
      that.request({
        method: 'post',
        url: '/manage/ossFile/listRecord',
        param: params,
        success: function (res) {
          if (res.code == 200) {
            that.recordList = res.result.list || []

          } else {
            that.$message.error('获取上传记录失败--' + res.msg)
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error('获取上传记录失败');
        }
      })
    },

    hideCourseDialog() {
      this.ifCourseDialog = false
      this.fileCourse = null
      this.ifAgain = false

    },
    // hideClassDialog() {
    //   this.ifClassDialog = false
    //   this.fileClass = null

    // },
    // hideFudaoDialog() {
    //   this.ifFudaoDialog = false
    //   this.fileFudao = null
    // },

    // hideAttendDialog() {
    //   this.ifAttendDialog = false;
    //   this.fileAttend = null
    // },
    // hideHomeworkDialog() {
    //   this.ifHomeworkDialog = false;
    //   this.fileHomework = null
    // },

    //获取学年列表
    getCourseYearArrFun() {
      let that = this;
      let params = {}
      that.request({
        method: 'post',
        url: '/manage/course/listCourseYear',
        param: params,
        success: function (res) {

          if (res.code == 200) {
            that.courseYearList = res.result || []

          } else {
            that.$message.error('获取学年失败--' + res.msg)
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error('获取学年列表异常');
        }
      })
    },
    //获取授课老师列表
    getTeacherList() {
      let that = this;
      let params = {
        pageNum: 1,
        pageSize: that.teacherTotalNum(),
        roleType: 'TEACHER' // ASSISTANT  TEACHER
      }
      that.request({
        method: 'post',
        url: '/manage/staff/listStaff',
        param: params,
        success: function (res) {

          if (res.code == 200) {
            that.teacherList = res.result.list || []

          } else {
            that.$message.error('获取助教老师失败--' + res.msg)
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error('获取助教老师列表异常');
        }
      })

    },
    //获取助教列表
    getAssistantList() {
      let that = this;
      let params = {
        pageNum: 1,
        pageSize: that.teacherTotalNum(),
        roleType: 'ASSISTANT' // ASSISTANT  TEACHER
      }
      that.request({
        method: 'post',
        url: '/manage/staff/listStaff',
        param: params,
        success: function (res) {

          if (res.code == 200) {
            that.assistantList = res.result.list || []

          } else {
            that.$message.error('获取助教老师失败--' + res.msg)
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error('获取助教老师列表异常');
        }
      })

    },


    searchFun() {
      this.curPage = 1
      this.tableData = []
      this.totalNum = 0
      this.getDataFun()

    },

    //重置搜索
    resetSearchFun() {
      let that = this;
      that.eeoCourseId = ''
      that.courseName = ''
      that.teacherId = ''
      that.assistantId = ''
      that.classType = '' //大小班
      that.courseType = ''
      that.courseYear = ''
      that.term = ''
      that.gradeList = []
      that.subjectTypeList = []

      that.searchFun();
    },
    handleScrollTop(e) {
      let that = this;
      let tmpLeft = e.target.scrollLeft
      if (that.$refs["myTable"]) {
        that.tableDom = that.$refs["myTable"].bodyWrapper
        that.tableDom.scrollTo(tmpLeft, 0)
      }
    },

    //获取数据列表
    getDataFun() {
      let that = this;
      that.pageLoading = true
      let param = {
        pageNum: that.curPage,
        pageSize: that.pageSize,
      };
      if (that.eeoCourseId) {
        param.eeoCourseId = that.eeoCourseId
      }
      if (that.courseName) {
        param.courseName = that.courseName
      }
      if (that.teacherId) {
        param.teacherId = that.teacherId
      }
      if (that.assistantId) {
        param.assistantId = that.assistantId
      }
      if (that.classType) {
        param.classType = that.classType
      }
      if (that.courseType) {
        param.courseType = that.courseType
      }
      if (that.courseYear) {
        param.courseYear = that.courseYear
      }
      if (that.term) {
        param.term = that.term
      }
      if (that.gradeList.length) {
        param.gradeList = that.gradeList
      }
      if (that.subjectTypeList.length) {
        param.subjectTypeList = that.subjectTypeList
      }

      that.request({
        method: 'post',
        url: '/manage/course/listCourse',
        param: param,
        success: function (result) {
          if (result.code == 200) {

            let data = result.result;
            that.tableData = data.list;
            that.totalNum = Number(data.total);

            setTimeout(() => {
              if (that.$refs["myTable"]) {
                that.tableWidth = that.$refs["myTable"].bodyWrapper.scrollWidth + 50 //没有fixed或者fixed=right,发现宽度少了
                that.$refs["myTable"].doLayout()
                that.tableDom = that.$refs["myTable"].bodyWrapper
                that.tableDom.addEventListener('scroll', () => {
                  let scrollLeft = that.tableDom.scrollLeft
                  // console.log('table scroll----', scrollLeft)
                  that.$refs.hkCustomScroll.scrollTo(scrollLeft, 0)
                })
              }
            }, 300)
          } else {
            console.log(result, '----not 200')
          }
          that.pageLoading = false
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取失败。')
          console.log(error, '-----fenye 获取link失败')
          that.pageLoading = false
        }
      })
    },
    //分页
    handleCurPageChange(val) {
      console.log(`当前页: ${val}`);
      this.curPage = val
      this.getDataFun();
    },
    //修改每页展示的记录个数
    handleSizeChange(val) {
      console.log(`每页显示: ${val} 条数据`);
      this.pageSize = val
      this.curPage = 1
      this.getDataFun();
    },

    //查看课节
    seeLessonFun(row) {
      this.$router.push('/courselessonlist?id=' + row.id + '&name=' + encodeURIComponent(row.courseName) + '&subject=' + encodeURIComponent(row.subjectType))
    },

    //期中报告
    seeMidTermFun(row) {
      this.$router.push('/midterm?id=' + row.id + '&name=' + encodeURIComponent(row.courseName) + '&subject=' + encodeURIComponent(row.subjectType))
    }

  }

}
</script>

<style lang="scss" scoped>
.hk_custom_table {
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: hidden !important;
  }
  //将el-table的横向滚动条给隐藏,只能写在每个页面，写在公共里面无效
}
.export-dialog {
  .export-main {
    padding-bottom: 20px;
    .export-upload {
      display: flex;
      align-items: center;
      .d-template {
        color: #f08d00;
      }
    }
    .export-remark {
      display: flex;
      align-items: center;
      padding-top: 8px;
      .ipt-remark {
        width: 200px;
        margin-right: 6px;
      }
    }
  }
  .export-record {
    .record-title {
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      padding-bottom: 12px;
    }
    .record-item-list {
      max-height: 480px;
      overflow: auto;
    }
    .record-item {
      font-weight: 400;
      font-size: 14px;
      color: #f08d00;
      line-height: 22px;
      text-align: left;
      .span {
        color: rgba(0, 0, 0, 0.85);
        padding: 0 4px;
      }
    }
  }
}
</style>